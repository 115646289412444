import React from 'react'
import { BrowserRouter as Router, Route } from "react-router-dom";
import WelcomePage from './components/WelcomePage';
import { connect } from 'react-redux';
import FeedPage from './FeedPage';


function RouteContainer(props) {
  return (
    <>
      {
        !props.isWelcomePageHidden ? <WelcomePage /> : (
          <Router>
            <Route path="/" exact component={FeedPage} />
            <Route path="/room/:roomid" component={FeedPage} />
            <Route path="/settings" component={FeedPage} />
          </Router>
        )
      }
    </>
  )
}


const mapStateToProps = state => ({isWelcomePageHidden: state.isWelcomePageHidden})

export default connect(
  mapStateToProps,
  null
)(RouteContainer);