import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { changeFeedSort } from '../redux/actions/allActions';
import { connect } from 'react-redux';

/**
 * Used for sorting "hot" vs "new" posts
 */
function SortToggle(props) {

  const changeSort = (e, newSort) => {
    // user clicked on the current sort (don't do anything)
    if(newSort == null) {
      return
    }
    props.changeFeedSort(newSort)
  }

  return (
    <div style={{flex: 'auto'}}>
      <ToggleButtonGroup value={props.feedSort} exclusive onChange={changeSort}>
        <ToggleButton value="hot">
          Hot
        </ToggleButton>
        <ToggleButton value="new">
          New
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}

const mapStateToProps = state => ({ feedSort: state.feedSort })
const mapDispatchToProps = {
  changeFeedSort,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SortToggle)
