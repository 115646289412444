import React, {useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function AddPostDialog(props) {

  const [caption, setCaption] = useState('');
  const [errorText, setErrorText] = useState('');
  const [isPosting, setIsPosting] = useState(false);

  const handleCaptionChange = e => {
    setCaption(e.target.value.substring(0,140))
  }

  const makePost = e => {
    setIsPosting(true);
    const data = new FormData()
    data.append('message', caption);
    data.append('group_id', "11111111-1111-1111-1111-111111111111");
    data.append('media', props.image)
    axios.post('https://api.notfornudes.com/posts/photo', data).then(response => {
      props.onSuccess();
      props.handleClose();
      setIsPosting(false);
    }).catch(error => {
      if(error.data) {
        setErrorText(error.data.errors[0].detail)
      } else {
        setErrorText("Error uploading image")
      }
      setIsPosting(false);   
    });
  }

  return (
    <div>
      <Dialog onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
        <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
          New Post
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            id="outlined-dense-multiline"
            label="Caption"
            margin="dense"
            variant="outlined"
            multiline
            fullWidth
            rowsMax="4"
            value={caption}
            onChange={handleCaptionChange}
          />
          <div>
            <img src={props.imageUrl} style={{maxWidth: '100%'}} alt="Background"/>
          </div>
          <p>{errorText}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={makePost} color="primary" disabled={isPosting}>
            Post
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}