import React, { Component } from 'react';
import './Feed.scss';
import Post from './components/Post';

class Feed extends Component {

  render() {
    return (
      <div className="feed">
      {
        this.props.posts.map((elem, i) => {
          return(
            <Post
              key={i}
              post={elem}
            >
              <img
                style={{height: '100%', objectFit: 'cover', maxHeight: 'inherit'}}
                width="100%"
                src={elem.media_url}
                alt="placeholder"></img>
            </Post>);
        })
      }
      </div>
    );
  }
}

export default Feed;
