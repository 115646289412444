import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import { isWelcomePageSeen } from '../utils/LocalStorageManager';

let defaultState = {
  feedSort: 'hot',
  isWelcomePageHidden: isWelcomePageSeen(),
}

export default function configureStore(initialState=defaultState) {
 return createStore(
   rootReducer,
   initialState,
   applyMiddleware(thunk)
 );
}