import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import {FileDropzone} from './DragAndDrop';
import AddPostDialog from './AddPostDialog';
import SortToggle from './SortToggle';
import { setWelcomePageHidden } from '../redux/actions/allActions';
import { connect } from 'react-redux';

class MaterialNavBar extends React.Component {

  state = {
    isModalOpen: false,
  }

  onFileInput = (files) => {
    // Prevent memory leak of any old files in memory
    URL.revokeObjectURL(this.state.currFile);

    this.setState({
      currFile: files[0],
      currFileUrl: URL.createObjectURL(files[0]),
      currFileName: files[0].name,
      currFileSize: files[0].size,
      currFileType: files[0].type,
      isModalOpen: true,
    });
  }

  onClose = () => {
    this.setState({
      isModalOpen: false,
    })
  }

  render() {
    return (
      <div style={{flexGrow: 1}}>
        <AppBar position="static">
          <Toolbar>
            {/* TODO <IconButton edge="start" color="inherit" aria-label="menu" style={{marginRight: '10px'}}>
              <MenuIcon />
            </IconButton> */}
            {/* <Typography variant="h6" style={{flexGrow: 1}}>
              NFN
            </Typography> */}
            <div style={{marginRight: '10px', flexGrow: '1'}}>
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => this.props.setWelcomePageHidden(false)}>
                <InfoIcon />
              </IconButton>
            </div>
            
            <SortToggle />
            <FileDropzone fileCallback={this.onFileInput} />
          </Toolbar>
        </AppBar>
        <AddPostDialog
          open={this.state.isModalOpen}
          imageUrl={this.state.currFileUrl}
          image={this.state.currFile}
          handleClose={this.onClose}
          onSuccess={this.props.refetch}
        />
      </div>
    );
  }
}

const mapDispatch = {
  setWelcomePageHidden
}

export default connect(
  null, mapDispatch
)(MaterialNavBar)