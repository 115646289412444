import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import AddIcon from '@material-ui/icons/AddCircleOutline';
import IconButton from '@material-ui/core/IconButton';


export function FileDropzone(props) {

  let {
    fileCallback,
    ...otherProps
  } = props;
  const onDrop = useCallback(acceptedFiles => {
    fileCallback(acceptedFiles);
  }, [fileCallback])
  const { getRootProps, getInputProps } = useDropzone({onDrop})

  return (
    <div {...getRootProps()} {...otherProps}>
      <input style={{height: "100%"}} {...getInputProps()}  />
      <IconButton edge="start" color="inherit" aria-label="add">
        <AddIcon />
      </IconButton>
    </div>
  )
}