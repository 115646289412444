import React, { useEffect, useState } from 'react';
import Feed from './Feed';
import './App.scss';
import { gql } from "apollo-boost";
import MaterialNavBar from './components/MaterialNavBar';
import { useLazyQuery, useApolloClient } from '@apollo/react-hooks';
import { connect } from 'react-redux';


const GET_POSTS = gql`
query Posts($order: String!) {
  posts(group_id: "11111111-1111-1111-1111-111111111111", order: $order) {
    id
    media_url
    type
    votes
    total_comments
    message
    width
    height
    created_at
    user_vote {
      value
    }
  }
}`

function FeedPage(props) {
  const [pageLoading, setPageLoading] = useState(true);
  
  const [, { newPostsLoading, }] = useLazyQuery(GET_POSTS);
  const client = useApolloClient();
  const [posts, setPosts] = useState([])

  useEffect(() => {
    var orderKey = "votes"
    if(props.feedSort === "new") {
      orderKey = "created_at"
    }
    client.query({
      query: GET_POSTS,
      variables: { order: orderKey }
    }).then(response => {
      setPosts(response.data.posts)
    })
    setPageLoading(false);
  }, [props.feedSort, client])

  const _readyToRender = () => {
    return !(pageLoading | newPostsLoading);
  }

  return ( 
    <>
      {
        !_readyToRender() ? <div>loading</div> : (
          <>
            <MaterialNavBar groupId="11111111-1111-1111-1111-111111111111" refetch={() => {
              client.query({
                query: GET_POSTS,
                variables: { order: 'created_at' }
              }).then(response => {
                setPosts(response.data.posts)
              })
            }}/>
            <Feed posts={posts} />
          </>
        )
      }
    </>
  )
}

const mapStateToProps = state => ({ feedSort: state.feedSort })

export default connect(
  mapStateToProps,
  null
)(FeedPage)