import React, { Component } from 'react';
import './App.scss';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { Provider } from 'react-redux';
import configureStore from './redux/store';
import RouteContainer from './RouteContainer';



// Setup Redux
const reduxStore = configureStore();

// Setup Graphql
const cache = new InMemoryCache();

// Use env variables to dynamically change the base url. We need full url for prod build but shortened for proxy on dev.
// const BASE_URL = process.env.NODE_ENV === "production" ? "https://api.notfornudes.com/graphql" : "/graphql";
const BASE_URL = "https://api.notfornudes.com/graphql";


const httpLink = new HttpLink({
  uri: BASE_URL,
  credentials: 'include',
});

const defaultOptions = {
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const client = new ApolloClient({
  link: httpLink,
  cache,
  defaultOptions,
  credentials: 'include', // set cookies
});

/**
 * The App :D
 */
export default class App extends Component {

  componentDidMount(props) {
    // scroll to top on reload because the async loading of posts causes automatic scroll
    // restoration to fail and scroll to the wrong spot
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Provider store={reduxStore}>
        <ApolloProvider client={client}>
          <ApolloHooksProvider client={client}>
            <RouteContainer />
          </ApolloHooksProvider>
        </ApolloProvider>
      </Provider>
      
    );
  }
}

