import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import TextField from '@material-ui/core/TextField';
import SendImg from '../static/send.png';
import IconButton from '@material-ui/core/IconButton';



const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0, 1),
    gridColumn: "1 / 9",
    overflowY: 'scroll',
    maxHeight: '400px'
  },
  paper: {
    maxWidth: 400,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(1.5),
  },
}));

const GET_COMMENTS = gql`
  query comments($post_id: String!) {
    comments(post_id: $post_id) {
      id
      message
      votes
      created_at
    }
  }
`;

const CREATE_COMMENT = gql`
  mutation create_comment($post_id: String!, $message: String!) {
    create_comment(post_id: $post_id, message: $message) {
      id
      message
      votes
      created_at
    }
  }
`;


  

export default function CommentFeed(props) {
  const classes = useStyles();

  const { loading, error, data, refetch } = useQuery(GET_COMMENTS, {
    variables: {post_id: props.postId},
  });

  const [commentText, setCommentText] = useState('')
  const [createComment] = useMutation(CREATE_COMMENT);

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  
  const handleTextFieldKeyDown = event => {
    switch (event.key) {
        case 'Enter':
            // call corresponding handler
            event.preventDefault();
            submitNewComment();
            break
        case 'Escape':
            // etc...
            break
        default: break
    }
  }

  const newCommentChange = event => {
    setCommentText(event.target.value)
  }

  const submitNewComment = async () => {
    // don't allow just whitespace or empty comments
    if(commentText.trim() === '') {
      return
    }
    try {
      await createComment({variables: {post_id: props.postId, message: commentText}});
      await refetch()
      if (error == null) {
        setCommentText('');
      } else {
        console.log(error)
      }
      
    } catch (error) {
      console.log(error)
      console.log(error.response)
    }
  }

  return (
    <div className={classes.root}>
      {
        data.comments.map((comment, index) => {
          return (
            <Paper className={classes.paper} key={index}>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item xs>
                  <Typography>{comment.message}</Typography>
                </Grid>
              </Grid>
            </Paper>
          )
        })
      }
      <Paper className={classes.paper}>
        <Grid container wrap="nowrap" spacing={1}>
          <Grid item xs>
          <TextField
            id="outlined-dense-multiline"
            margin="dense"
            variant="outlined"
            multiline
            rowsMax="4"
            placeholder="New Comment"
            onKeyDown={handleTextFieldKeyDown}
            onChange={newCommentChange}
            value={commentText}
            fullWidth
          />
          </Grid>
          <Grid item xs={2} style={{
            marginTop: '5px'
          }}>
            <IconButton style={{width: '45px'}} className='send-button' onClick={submitNewComment}>
            <img className="button-image" src={SendImg} alt="Send Button"/>
          </IconButton>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}