// types of action
export const ActionTypes = {
  CHANGE_FEED_SORT: "CHANGE_FEED_SORT",
  SET_WELCOME_PAGE_HIDDEN: "SET_WELCOME_PAGE_HIDDEN"
};

// actions
export const changeFeedSort = sort => ({
  type: ActionTypes.CHANGE_FEED_SORT,
  payload: sort
});

// actions
export const setWelcomePageHidden = isHidden => ({
  type: ActionTypes.SET_WELCOME_PAGE_HIDDEN,
  payload: isHidden
});